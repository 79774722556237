.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #61dafb;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

a {
  color: #61dafb;
  text-decoration: none;
  font-weight: bold;
  padding: 0.2rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  font-size: 6rem;
  margin: 0;
}

ul,
li {
  list-style: none;
  margin: 1rem;
  padding: 0;
}

.animatedBorder {
  --angle: 0deg;

  border: 1px solid;
  margin-bottom: 8px;
  border-image: conic-gradient(
      from var(--angle),
      red,
      yellow,
      lime,
      aqua,
      blue,
      magenta,
      red
    )
    1;

  animation: 1s rotate linear infinite;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
